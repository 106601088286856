<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <reporting-home
          :username="username"
          :id="id"
        ></reporting-home>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReportingHome from '@/components/Reporting/Home'

export default {
  props: ['username', 'id'],
  components: {
    ReportingHome
  }
}
</script>
