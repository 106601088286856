<template>
  <v-window v-model="window" touchless>
    <v-window-item
      :key="0"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text" flat>
          <v-toolbar-title>Reports</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <loading-bar :value="loading"></loading-bar>
        <v-card-text>
          <v-list two-line>
            <template v-for="(report, i) in reports">
              <v-list-item
                :key="`${report.id}item`"
              >
                <v-list-item-icon>
                  <v-icon>fas fa-file-chart-line</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ report.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ report.description }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon :to="{name: 'report-details', params: {id: report.id, username: username}}">
                    <v-icon color="grey lighten-1">fas fa-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`${report.id}div`" v-if="i + 1 < reports.length"></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-window-item>
    <v-window-item :key="1">
      <div>
        <v-btn color="color3" text small :to="{name: 'organization-reports', params: {username: username}}" exact>
          <v-icon small class="mr-2">fas fa-caret-left</v-icon> back to report home
        </v-btn>
      </div>
      <report-details
        :username="username"
        :report="selectedReport"
        v-if="selectedReport && !selectedReport.isPlayerReport"
      ></report-details>
      <ntdp-player-report v-else-if="selectedReport && selectedReport.props.includes('ntdp')"
        :username="username"
        :report="selectedReport"
      ></ntdp-player-report>
    </v-window-item>
  </v-window>
</template>

<script>
const ReportDetails = () => import('@/components/Reporting/CustomReportDisplay.vue')
const NtdpPlayerReport = () => import('@/components/Reporting/NtdpPlayerReport.vue')

export default {
  props: ['username', 'id'],
  data () {
    return {
      reports: [],
      loading: false,
      search: null,
      window: 0
    }
  },
  computed: {
    selectedReport () {
      return this.id && this.reports.find(f => f.id === +this.id)
    }
  },
  methods: {
    getReports () {
      this.loading = true
      this.$VBL.reports.getList(this.username)
        .then(r => {
          this.reports = r.data
          this.checkId()
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    checkId () {
      if (this.reports.length === 0) return
      if (this.id) {
        if (this.window === 0) {
          this.window = 1
        }
      } else {
        if (this.window === 1) {
          this.window = 0
        }
      }
    }
  },
  watch: {
    id: 'checkId'
  },
  mounted () {
    this.getReports()
  },
  components: {
    ReportDetails,
    NtdpPlayerReport
  }
}
</script>
